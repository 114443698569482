import { ThemeProvider } from '@mui/material/styles';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { Application } from './Application';
import { store } from './store';
import { theme } from './theme';

export const initApp = (node?: Element) => {
  const rootNode = node || document.getElementById('root');

  ReactDOM.render(
    <StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Application />
        </ThemeProvider>
      </Provider>
    </StrictMode>,
    rootNode,
  );
};
