import { useLayoutEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import { NotificationProvider } from 'react-ui-kit-exante';

import { ErrorFallback } from 'components/ErrorFallback';
import { Header } from 'components/Header/Header';
import { ROUTES } from 'routes';
import { AppVersion } from 'shared/AppVersion/AppVersion';
import { getBaseName } from 'utils/getBaseName';

import styles from './Application.module.css';
import { TradingTerminals } from './pages/TradingTerminals/TradingTerminals';

export const Application = () => {
  const history = window.runUIhistoryContainer;
  const [navigator, setNavigator] = useState({
    action: history?.action,
    location: history?.location,
  });

  useLayoutEffect(() => {
    if (history) {
      history.listen(() => {
        return setNavigator({
          action: history.action,
          location: history.location,
        });
      });
    }
  }, [history]);

  const routes = (
    <Routes>
      <Route path={ROUTES.TRADING_TERMINALS} element={<TradingTerminals />} />
    </Routes>
  );
  return (
    <>
      <NotificationProvider />
      {window.runUIhistoryContainer ? (
        <Router
          location={navigator.location}
          navigationType={navigator.action}
          navigator={history}
        >
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            {routes}
          </ErrorBoundary>
        </Router>
      ) : (
        <BrowserRouter basename={getBaseName()}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Header />
            <div className={styles.Container}>
              {routes}
              <AppVersion />
            </div>
          </ErrorBoundary>
        </BrowserRouter>
      )}
    </>
  );
};
