import { createTheme } from '@mui/material/styles';

const defaultTheme = {
  palette: {
    primary: {
      light: '#14AF5A',
      main: '#007F39',
    },
    success: {
      main: '#409F6B',
    },
    text: {
      primary: '#252C2E',
      secondary: '#4E5D60',
      disabled: '#B8BBB7',
    },
    warning: {
      main: '#9F4040',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
  font: {
    main: 'Inter, sans-serif',
    header: 'FoxSansPro, sans-serif',
  },
  color: {
    bg: {
      basic: '#F3F3F3',
      primary: '#FFFFFF',
      secondary: '#F7F7F7',
    },
    button: {
      primary: {
        bg: '#02833C',
        gr: {
          default: 'linear-gradient(180deg, #029E47 0%, #01803A 100%)',
          focus: '#018B3F',
          hover: 'linear-gradient(180deg, #01C056 0%, #01803A 100%)',
        },
        typo: {
          focus: '#CCE6D8',
        },
      },
      radical: {
        bg: '#8C3434',
        gr: {
          default: 'linear-gradient(180deg, #9F4040 0%, #8B2D2D 100%)',
          focus: '#AB4040',
          hover: 'linear-gradient(180deg, #CA4747 0%, #A63636 100%)',
        },
        typo: {
          focus: '#E6CCCC',
        },
      },
      secondary: {
        bg: '#EAEAEA',
        gr: {
          default: 'linear-gradient(180deg, #F9F9F9 0%, #F0F0F0 100%)',
          focus: '#E6E6E6',
          hover: 'linear-gradient(180deg, #F0F0F0 0%, #E4E4E4 100%)',
        },
        typo: {
          focus: '#BABABA',
        },
      },
    },
    controls: {
      gr: 'linear-gradient(180deg, #029E47 0%, #01803A 100%)',
    },
    dropdown: {
      bg: {
        default: '#FFFFFF',
        hover: '#FBFBFB',
      },
      border: '#EBEBEB',
      error: '#BF3B4B',
      list: {
        bg: {
          focus: '#F3F3F3',
          hover: '#F2F8F2',
        },
      },
    },
    icon: {
      action: '#007F39',
      ghost: '#B8BBB7',
      hover: '#03BF56',
      inverse: '#FFFFFF',
      primary: '#252C2E',
      promo: '#4E5D60',
      radical: '#9F4040',
      secondary: '#818C99',
      source: '#409F6B',
    },
    input: {
      bg: {
        default: '#FFFFFF',
        hover: '#FBFBFB',
      },
      border: '#F2F1F1',
      error: '#BF3B4B',
    },
    line: {
      action: '#007F39',
      secondary: '#F2F1F1',
      level: {
        page: '#F7F7F7',
      },
    },
    typo: {
      action: '#007F39',
      ghost: '#B8BBB7',
      hover: '#03BF56',
      inverse: '#FFFFFF',
      primary: '#252C2E',
      promo: '#4E5D60',
      radical: '#9F4040',
      secondary: '#818C99',
      source: '#409F6B',
    },
    table: {
      bg: {
        basic1: '#FAFAFA',
        basic2: '#FFFFFF',
        hover: '#F2F8F2',
        radical: '#EFE8E8',
        source: '#EEF6F2',
      },
    },
    text: {
      darkGrey: {
        light: '#CACCC9',
      },
    },
    notifications: {
      success: '#409F6B',
      error: '#9F4040',
      warning: '#EC9F0B',
    },
  },
  effect: {
    controls: {
      inter:
        'inset -7.79953e-16px -4.8px 9.6px rgba(33, 229, 100, 0.24), inset 7.79953e-16px 4.8px 9.6px rgba(12, 211, 161, 0.41)',
      drop: '0px 2.32257px 6.19352px rgba(0, 0, 0, 0.15)',
    },
  },
};

export const theme = createTheme(defaultTheme);
