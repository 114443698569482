export enum Headers {
  X_AUTH_SESSIONID = 'x-auth-sessionid',
  CONTENT_TYPE = 'Content-Type',
  AUTHORIZATION = 'Authorization',
}

export enum Methods {
  DELETE = 'DELETE',
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  HEAD = 'HEAD',
}

export enum Endpoint {
  Brandings = '/brandings',
  USERS = '/users',
}

export const PROD_API = {
  BS: 'branding.exante.eu',
  BO: 'node-back.exante.eu',
};

export const TEST_API = {
  BS: 'branding-stage.exante.eu',
  BO: 'node-back-stage.exante.eu',
};

export const BS_API = '/api';
