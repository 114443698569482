import { styled } from '@mui/material/styles';

import { IThemeProps } from '../types';

export const StyledInProgress = styled('div')(() => ({
  color: '#EA9C0B',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}));

export const StyledDone = styled('div')(({ theme }: IThemeProps) => ({
  color: theme.color.icon.action,
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  cursor: 'pointer',
}));

export const StyledFailed = styled('div')(() => ({
  color: '#BF3B4A',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}));
