import { Link } from '@mui/material';

import { ReactComponent as BuildDeployIcon } from '../../../assets/i/BuildDeploy.svg';
import { ReactComponent as FailedIcon } from '../../../assets/i/Failed.svg';
import { ReactComponent as InProgressIcon } from '../../../assets/i/InProgress.svg';
import { IDeployCell } from '../types';

import { StyledInProgress, StyledDone, StyledFailed } from './styled';

export const DeployCell: ({
  status,
  triggerRelease,
  branding,
  platform,
  releaseArtifact,
  releaseId,
}: IDeployCell) => JSX.Element = ({
  status,
  releaseId,
  triggerRelease,
  releaseArtifact,
}) => {
  const { linux, macos, windows } = releaseArtifact;

  switch (status) {
    case 'done':
      return (
        <StyledDone onClick={triggerRelease}>
          <BuildDeployIcon />
          Deploy
        </StyledDone>
      );
    case 'release-started':
      return (
        <StyledInProgress>
          <InProgressIcon />
          In Progress
        </StyledInProgress>
      );
    case 'released':
      return (
        <StyledDone>
          {windows && <Link href={windows}>Windows</Link>}
          {linux && <Link href={linux}>Linux</Link>}
          {macos && <Link href={macos}>macOS</Link>}
        </StyledDone>
      );
    case 'error':
      return releaseId ? (
        <StyledFailed>
          <FailedIcon />
          Failed
        </StyledFailed>
      ) : (
        <span>–</span>
      );
    default:
      return <span>–</span>;
  }
};
