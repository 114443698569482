import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  PanelHeader,
  SelectedListWithSearch,
  IColumn,
  Notification,
} from 'react-ui-kit-exante';

import { useNodeBackService } from '../../services/NodeBack.service';

import { CommonTable } from './CommonTable/CommonTable';
import { TerminalTable } from './TerminalTable/TerminalTable';
import type { ITerminalOption } from './const';
import { ATP_NAME, TERMINAL_LIST } from './const';
import { getColumns, getCommonColumns } from './helpers';
import {
  Root,
  StyledSelectWrapper,
  StyledTableWrapper,
  StyledTTRoot,
} from './styled';
import { IBuild } from './types';
import { useBranding } from './useBranding';

export const TradingTerminals = () => {
  const { getCurrentBrandings, getBuildsHistory } = useNodeBackService();
  const [platform, setPlatform] = useState('All');
  const [brandings, setBrandings] = useState([]);
  const [pendingBuilds, setPendingBuilds] = useState([]);
  const {
    brandingName,
    setBrandingName,
    triggerAtpBuild,
    triggerRelease,
    checkBrandingBuildStatus,
    cancelBuild,
    buildsHistory,
    setBuildsHistory,
    setIsUpdate,
    isUpdate,
  } = useBranding(platform);

  const onTriggerBuild = useCallback(async () => {
    await triggerAtpBuild(brandingName);
    setIsUpdate(true);
    Notification.success({
      title: 'Branding build has been triggered successfully',
    });
  }, [brandingName]);

  const onTriggerCancel = useCallback(async (data) => {
    await cancelBuild(data);
    setIsUpdate(true);
    Notification.success({
      title: 'Branding build has been canceled successfully',
    });
  }, []);

  const terminalTableColumns: IColumn<IBuild> = useMemo(
    () => getColumns({ triggerRelease, onTriggerCancel }),
    [triggerRelease],
  );
  const commonTableColumns: IColumn<IBuild> = useMemo(
    () => getCommonColumns({ triggerRelease, onTriggerCancel }),
    [triggerRelease],
  );

  useEffect(() => {
    (async () => {
      try {
        const currentBrandings = await getCurrentBrandings();
        const filteredBrandings = currentBrandings.filter(
          (branding: string) => branding !== 'ALL',
        );

        setBrandings(filteredBrandings);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);
  useEffect(() => {
    (async () => {
      try {
        if (isUpdate) {
          const buildsData = await getBuildsHistory(brandingName);
          setBuildsHistory(buildsData.data);
          const pendingJobs = buildsData.data.filter(
            (build: IBuild) =>
              build.status === 'created' || build.status === 'release-started',
          );
          setPendingBuilds(pendingJobs);
          setIsUpdate(false);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [brandingName, isUpdate]);

  useEffect(() => {
    pendingBuilds.forEach((build: IBuild) => {
      checkBrandingBuildStatus(build.branding);
    });
  }, [pendingBuilds]);

  useEffect(() => {
    (async () => {
      switch (platform) {
        case ATP_NAME:
          // eslint-disable-next-line no-case-declarations
          const currentBrandings = await getCurrentBrandings();
          // eslint-disable-next-line no-case-declarations
          const filteredBrandings = currentBrandings.filter(
            (branding: string) => branding !== 'ALL',
          );
          setBrandings(filteredBrandings);
          return;
        default:
          setBrandings([]);
      }
    })();
  }, [platform]);

  const handleTerminalChange = useCallback((value: ITerminalOption) => {
    const { name } = value;
    setPlatform(name);
  }, []);

  const resetPlatform = useCallback(() => {
    setPlatform('All');
    setBrandingName('');
    setIsUpdate(true);
  }, []);

  const onBrandingChange = useCallback(
    (data) => {
      setBrandingName(data);
      setIsUpdate(true);
    },
    [brandingName, isUpdate],
  );

  const table = useMemo(() => {
    switch (platform) {
      case ATP_NAME:
        return (
          <TerminalTable
            columns={terminalTableColumns}
            brandings={brandings}
            data={buildsHistory}
            platform={platform}
            brandingName={brandingName}
            setBrandingName={onBrandingChange}
            triggerBuild={onTriggerBuild}
            resetPlatform={resetPlatform}
          />
        );
      case 'All':
      default:
        return (
          <CommonTable data={buildsHistory} columns={commonTableColumns} />
        );
    }
  }, [brandings, brandingName, buildsHistory, platform]);

  return (
    <Root>
      <PanelHeader title="Trading Terminals" />
      <StyledTTRoot>
        <StyledSelectWrapper>
          <SelectedListWithSearch
            selected={platform}
            options={TERMINAL_LIST}
            onListItemClick={handleTerminalChange}
          />
        </StyledSelectWrapper>
        <StyledTableWrapper>{table}</StyledTableWrapper>
      </StyledTTRoot>
    </Root>
  );
};
