import { styled } from '@mui/material/styles';
import { FC, useLayoutEffect, useState } from 'react';
import { TDefaultTheme } from 'react-ui-kit-exante';

const AppVersionStyled = styled('div')(({ theme }: TDefaultTheme) => ({
  padding: '20px',
  color: theme?.color?.typo?.ghost,
  fontSize: '11px',
}));

export const AppVersion: FC = () => {
  const [version, setVersion] = useState<string | null>(null);
  useLayoutEffect(() => {
    if (!window.runUIhistoryContainer) {
      setVersion(process?.env?.REACT_APP_VERSION || null);
    }
  }, []);
  return version ? (
    <AppVersionStyled>Version {version}</AppVersionStyled>
  ) : null;
};
