import { styled } from '@mui/material/styles';

import { IThemeProps } from './types';

export const Root = styled('div')(() => ({
  backgroundColor: '#fff',
}));

export const StyledTTRoot = styled('div')(() => ({
  display: 'flex',
}));

export const StyledSelectWrapper = styled('div')(() => ({
  minWidth: '240px',
  padding: '24px',
  border: '1px solid #EEF1F1',
  height: '100vh',
}));

export const StyledTableWrapper = styled('div')(() => ({
  border: '1px solid #EEF1F1',
  height: '100vh',
  width: '100%',
  overflow: 'auto',
}));

export const TableControls = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const TableTitle = styled('div')(() => ({
  display: 'flex',
  gap: '24px',
  flexDirection: 'row',
  alignItems: 'center',
}));

export const TriggerBuildButtonWrapper = styled('div')(() => ({
  display: 'flex',
  marginRight: '32px',
  gap: '16px',
}));

interface IStyledBuildButton extends IThemeProps {
  isDisabled: boolean;
}

export const StyledTriggerBuildButton = styled('div')(
  ({ isDisabled, theme }: IStyledBuildButton) => ({
    fontSize: '15px',
    lineHeight: '24px',
    color: isDisabled ? theme.color.icon.secondary : theme.color.icon.action,
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    fontFamily: theme.font.main,
    cursor: isDisabled ? 'cursor' : 'pointer',
  }),
);
